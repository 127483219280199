// i18next-extract-mark-ns-start visa-mastercard-for-woocommerce
//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {List} from 'components/List';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_visamastercard from 'images/woocommerce_visa-mastercard.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="visa-mastercard-for-woocommerce"
        title={t('Add Credit Card Payment in Woocommerce | MONEI')}
        description={t(
          'Accept credit card payments in your WooCommerce store with MONEI’s e-commerce payment gateway. Visa, Mastercard, JCB, Diners, and UnionPay. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Quickly and easily add credit card payments to your WooCommerce store</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                You’ve chosen your WooCommerce template and set up your product pages. Now it’s time
                to configure credit card payments in your store. But you need a payment gateway that
                connects with WooCommerce and supports credit card payments.
              </Trans>
              <Trans parent="p">
                Use our advanced e-commerce payment gateway to configure the widest range of payment
                methods in a single dashboard. Set up an account and you’ll be prepared to accept
                fast and secure credit card payments in your WooCommerce store. Credit card brands
                include Visa, Mastercard, JCB, Diners, and UnionPay.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_visamastercard}
              alt="Woocommerce visa mastercard"
              title="Woocommerce visa mastercard"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>
                  Accept credit cards and more payment methods in your WooCommerce store
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Paying by credit card is popular, but it shouldn’t be the only{' '}
                <InternalPageLink slug="payment-methods">online payment method</InternalPageLink>{' '}
                you accept in your WooCommerce store. Shoppers today are looking for new ways to pay
                online including{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/paypal">Paypal</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,
                and{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                . Along with <BlogLink slug="local-payment-methods">local payment methods</BlogLink>{' '}
                such as <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in
                Spain.
              </Trans>
              <Trans parent="p">
                Accepting more payment methods helps you reach more people, improve customer
                satisfaction, and boost sales. That’s why we combine all major and{' '}
                <BlogLink slug="alternative-payment-methods">alternative payment methods</BlogLink>{' '}
                in one platform. Join us today so you can manage all your payments from a single
                dashboard.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Grow your sales revenue</Trans>
              </SectionHeader>
              <Trans parent="p">
                Approve more payments, improve the checkout experience, and increase conversion
                rates thanks to our{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  payments orchestration
                </InternalPageLink>{' '}
                feature. Instead of depending on{' '}
                <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone to
                process credit card payments, our smart routing technology sends online transactions
                to multiple payment processors. This way, if one is experiencing downtime, the
                payment is routed to the best-performing processor to reduce{' '}
                <BlogLink slug="online-payment-failure-message">online payment failures</BlogLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the lowest WooCommerce payment gateway transaction fees</Trans>
              </SectionHeader>
              <Trans parent="p">
                As you sell more, you’ll pay less on transaction fees, thanks to our dynamic billing
                model. We’re the only payment gateway to offer sliding scale pricing so you’re left
                with more money to reinvest and grow your e-commerce business.
              </Trans>
              <Trans parent="p">
                Use a WooCommerce payment gateway that helps you scale your online store —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>
                Connect your WooCommerce store with MONEI to start accepting credit card payments
                (and more)
              </Trans>
            </SectionHeader>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/en-us/sections/360005119117-Credit-card">
                  Configure credit card payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Download{' '}
                <AnchorLink href="https://wordpress.org/plugins/monei/">
                  our WooCommerce plugin
                </AnchorLink>{' '}
                and connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "visa-mastercard-for-woocommerce"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
